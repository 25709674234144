import { customElement, inject } from 'aurelia-framework';
import { BaseComponent }         from 'resources/elements/aurelia-form/components/base-component';
import { AppContainer }          from 'resources/services/app-container';
import { CustomHttpClient }      from 'resources/services/custom-http-client';

@inject(AppContainer, CustomHttpClient)
@customElement('form-uniform-file')
export class FormUniformFile extends BaseComponent {
    modelElementId = 'uniform_uploader_';

    fileName = null;

    constructor(appContainer, httpClient) {
        super(appContainer);
        this.httpClient = httpClient;

        // Generate unique ID using random number
        let randomNumber = Math.floor(Math.random() * (65536 - 1 + 1) + 1);
        this.modelElementId += randomNumber;
    }

    /**
     * Creates element
     *
     * @returns {Promise}
     */
    createElement() {
        return new this.simplePromise(() => {
            let self      = this;
            let token     = self.appContainer.localStorage.authToken();
            let fileInput = $('#' + this.modelElementId);
            let url       = self.model.element.url;

            if (!url) return;

            self.setMockFile();

            // Watch file input change
            $(fileInput).on('change', async function (event) {

                const input = event.target;
                self.error  = false;

                // If user selected a file, try to upload it
                if (input.files && input.files.length > 0) {
                    let response = await self.uploadFile(input.files[0], url, token);

                    if (response.status === false) {
                        self.fileName = response.errors.replace(/<[^>]*>/g, '');
                        self.error    = true;
                        return;
                    }

                    // If valid file, either set complete file or only id
                    if (response.file) {
                        self.fileName = response.file.display_name;
                        if (self.model.element.returnModel) {
                            self.model.value = response.file
                        } else {
                            self.model.value = parseInt(response.file.id);
                        }
                    }
                } else {
                    self.fileName    = null;
                    self.model.value = null;
                }
            })
        });
    }

    /**
     * Destroys element
     *
     * @returns {Promise}
     */
    destroyElement() {
        return new this.simplePromise(() => {
            let fileInput = $('#' + this.modelElementId);

            // Unwatch file input change
            $(fileInput).off('change');
        });
    }

    /**
     * Simple method to upload file using FormData
     *
     * @param file
     * @param url
     * @param token
     * @returns {Promise<any>}
     */
    async uploadFile(file, url, token) {
        // Early bird gets the worm.
        if (!file || !url || !token) {
            return;
        }

        // Create FormData and append file
        const formData = new FormData();
        formData.append('file', file);

        try {
            // Send FormData to backend, as { file: (binary) }
            const response = await fetch(url, {
                method:  'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body:    formData,
            });

            return await response.json();
        } catch (error) {
            console.error('Error uploading file: ', error);
        }
    }

    setMockFile() {
        try {
            if (!this.model.element.mockFile) return;

            // Set file name
            this.fileName = this.model.element.mockFile.display_name;

            // Either set complete file or only id
            if (this.model.element.returnModel) {
                this.model.value = this.model.element.mockFile;
            } else {
                this.model.value = this.model.element.mockFile.id;
            }
        } catch (error) {
            console.error('Error setting mock file: ', error)
        }
    }
}
